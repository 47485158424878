// SHEROES SKIN <- FIBEL SKIN
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');

// @font-face {
//   font-family: 'Fibel';
//   src: url('./assets/fonts/font.woff2') format('woff2'),
//     url('./assets/fonts/font.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;
//   font-display: auto;
// }

body {
  //color: #2b2b2b;
  background-image: linear-gradient($gray-200, #fff);
}

// #main {
//   background-image:  url('./assets/images/character.png');
//   background-position: bottom left;
//   background-repeat: no-repeat;
//   background-size: contain;
// }

#root {
  padding-top: 0;
}

.btn,
.loading-spinner-content {
  text-transform: uppercase;
}

h1 {
  letter-spacing: 1px;
  color: $primary;
}

// NAV

.navbar-light {
  background: white !important;
  box-shadow: $box-shadow-xl;
  //transition: background 0.2s;
  // background-color: rgba($color: $white, $alpha: 0.9) !important;
  // body[data-scrolled] & {
  //   background-color: $white !important;
  // }
}

.product-listing-page .navbar-brand {
  opacity: 1;
  // transform: translateY(-10px);
  // transition: all 0.1s;
  // transition-delay: 0;
}
// body[data-scrolled] {
//   .navbar-brand {
//     opacity: 1;
//     transition-delay: 0.2s;
//
//   }
// }

#header .navbar .navbar-brand {
  img {
    height: 1.5rem;
    max-height: 1.5rem;

    @include media-breakpoint-up(md) {
      height: 3.5rem;
      max-height: 3.5rem;
    }

    &:hover,
    &:focus {
      filter: opacity(0.66);
    }
  }
}

.navbar .hamburger {
  color: $primary;
  @include media-breakpoint-down(sm) {
    padding-left: 0px;
  }
}

.navbar .secondary-menu,
.navbar .main-menu {
  display: flex;
  align-items: center;
}

.navbar-toggler {
  border: none;
  transition: transform 0.4s;
  padding: 0;
  outline: 0 !important;

  .navbar-toggler-icon {
    width: 2em;
    height: 2em;
  }
}

.navbar .nav-item.last {
  .nav-link {
    @include media-breakpoint-down(sm) {
      padding-right: 0px;
    }
  }
}

.offcanvas-collapse {
  .navbar-nav .nav-item {
    margin-bottom: 0.5rem;
    background-color: rgba($color: $primary, $alpha: 0.9) !important;
    a {
      color: #fff;
    }
    a.active,
    a:hover {
      color: $gray-300;
    }
    // font-family: $font-family-heading;
    font-size: $h5-font-size;
    text-transform: uppercase;
    box-shadow: -20px 0px 20px 0px rgba(0, 0, 0, 0.5);
  }
}

.offcanvas-collapse {
  //box-shadow: 15px 10px 70px -35px rgba(0,0,0,0.75);
  box-shadow: none;
  background-color: transparent;
}
.offcanvas-collapse-left {
  bottom: auto;
  overflow: visible;
}

.validation-alert {
  @extend .text-danger;

  .validation-alert--message {
    @extend .font-weight-bold;
  }
}

// OC CART

.offcanvas-collapse-right {
  background-color: rgba($color: #fff, $alpha: 0.9) !important;
  box-shadow: -15px 10px 70px -35px rgba(0, 0, 0, 0.75);
  h5 {
    font-size: $h5-font-size;
    text-transform: uppercase;
    color: $gray-900 !important;
  }
}

.cart-listing,
.list-group-item-secondary {
  background-color: white;
}

// MAKI

.maki {
  background-color: $white !important;
  box-shadow: $box-shadow;
  padding-top: 1em;
  transition: all 0.2s ease;
  &:hover  {
    background-color: $white;
    box-shadow: $box-shadow-lg;
  }

  .card-title {
    text-align: center;
    font-size: $h5-font-size !important;
    letter-spacing: 1px;
    color: $primary;
    font-size: $h3-font-size;
    justify-content: end !important;

    .title {
      font-family: $headings-font-family;
    }

    .sub-title {
      font-family: $font-family-sans-serif;
      letter-spacing: 0px;
    }

    .sub-title,
    .price {
      font-size: $font-size-base;
      color: $body-color;
    }
    .price {
      font-weight: bold;
      font-family: $font-family-sans-serif;
      font-size: $font-size-base;
      letter-spacing: 0px;
      @extend .mt-2;
    }
  }
}

// PDP

.detail-page {
  h1 {
    margin-top: 0;
  }
  .product-header-title {
    line-height: 1.4;
  }
}

.carousel-control-prev,
.carousel-control-next {
  color: $primary;
  &:hover,
  &:focus {
    color: lighten($primary, 0.2);
  }
}

.blurry-background-image {
  background-color: transparent;
}

.blurry-background-image:before {
  background-image: none;
}

.carousel-indicators {
  @include media-breakpoint-down(sm) {
    display: none;
  }
  position: relative;
  > a,
  > .btn {
    img {
      filter: brightness(0.5);
      margin: 0.3rem !important;
    }

    &:hover,
    &:focus,
    &.active {
      img {
        filter: brightness(1);
      }
    }
  }
}

.product-thumb {
  box-shadow: none;
  border: none;
}
.radio-list-group .product-thumb {
  box-shadow: 0 0 0 1px $dark;
  border: 3px solid $white;
  background: $white;
}

// PDP MOBILE

.container.detail-page {
  @include media-breakpoint-down(md) {
    form {
      text-align: center;
    }
  }
}

// FOOTER

#footer {
  background-color: $white;
  //border-bottom: 10px solid $primary;
  a {
    color: $body-color;
  }
  h5 {
    letter-spacing: 1px;
    color: $primary;
    font-size: $h3-font-size;
  }
}

#product-listing {
  padding-top: 2rem;
  padding-bottom: 3rem;
}

.hero-slider {
  margin-bottom: 0;
}

// CARMENS SPECIALS
// BACKDROP

.modal-backdrop.show {
  backdrop-filter: none !important;
}

.offcanvas-collapse,
.offcanvas-collapse-right,
.radio-list-group,
.navbar-nav .nav-item {
  box-shadow: none !important;
}

legend {
  font-weight: $headings-font-weight;
}

.shoppingcart-finisher {
  background-color: rgba(teal, 0.8);
}

.product-header-title .sub-title {
  font-family: $font-family-sans-serif;
  letter-spacing: 0;
  font-size: 1.1rem;
  color: $body-color;
}

.product-content {
  background-color: transparent;
}

.header {
  background-image: url(./assets/images/header.png);
  box-shadow: $box-shadow-xl;
  background-size: cover;
  background-position: center center;
  height: 75vh;
}

// safari fix from-to dates on nigiri
@include media-breakpoint-up(lg) {
  .nigiri-date {
    text-align: center;
    flex-direction: column;
  }

  .nigiri-date * + * {
    margin-top: -1rem;
  }
}
